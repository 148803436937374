import { useParams } from "react-router-dom";
import { useRequest } from "ahooks";
import { Spinner } from "../../common/Spinner/Spinner";
import { fetchReport } from "@/api/reports";
import { ReportForm } from "./ReportForm";

export const ReportPage = () => {
  const { id } = useParams<{ id: string }>();
  const { data: report, loading } = useRequest(() => fetchReport(id), {
    refreshDeps: [id],
  });
  if (loading) {
    return <Spinner />;
  }

  return <ReportForm initialValues={report} />;
};
