import { useParams } from "react-router-dom";
import { useRequest } from "ahooks";
import { fetchEmailAlert } from "@/api/email-alerts";
import { Spinner } from "@/components/common/Spinner/Spinner";
import { EmailAlertForm } from "@/components/EmailAlerts/Details/EmailAlertForm";

export const EmailAlertPage = () => {
  const { id } = useParams<{ id: string }>();

  const { data: alert, loading } = useRequest(() => fetchEmailAlert(id), {
    refreshDeps: [id],
  });

  if (loading) {
    return <Spinner />;
  }

  return <EmailAlertForm initialValues={alert} />;
};
