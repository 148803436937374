import { Button, Col, Form, Row, Select } from "antd";
import {
  AssetType,
  capitalize,
  getTemplateTypeLabel,
  mappingTemplates,
  Operation,
  TemplateType,
} from "@/utils";
import { OpenplayObjectType } from "@/types/common";
import { mappingTemplatesToOptions } from "../../common/CommonMappings/utils";
import { toOptions } from "@/utils/toOptions";
import * as R from "ramda";
import { Box } from "../../common/Box/Box";
import Icon from "@ant-design/icons";
import { DropdownArrow } from "../../common/Icons";
import { SalesforceObjectPicker } from "@/components/SalesforceObjects/SalesforceObjectPicker";
import { getOpenPlayEquivalent } from "@/utils/getOpenPlayEquivalent";
import { getTabSourceLabel, TabSource } from "@/utils/tab-source";

export const TemplateTypeSelector = ({ onSelect }) => {
  const [form] = Form.useForm();

  const type = Form.useWatch("type", form);
  const operation = Form.useWatch("operation", form);
  const targetObject = Form.useWatch("targetObject", form);
  const sourceObject = Form.useWatch("sourceObject", form);

  const handleFinish = (values) => {
    const result = {
      type: values.type,
    };
    if (values.mappingTemplate) {
      const [from, to] = values.mappingTemplate.split("_to_");
      result.inputFormat = from;
      result.outputFormat = to;
      result.operation = values.operation;
      result.targetObject = values.targetObject;
    }
    if (values.assetType) {
      result.assetType = values.assetType;
    }
    if (values.type === TemplateType.SpreadsheetColumnSet) {
      result.sourceObject = values.sourceObject;
      result.source = getOpenPlayEquivalent(values.sourceObject) ? values.source : TabSource.Salesforce;
    }
    onSelect(result);
  };

  const availableMappingTemplates = mappingTemplates
    .filter((template) => template.operation === operation && template.targetObjects.includes(targetObject))
    .map((template) => template.formats);

  const handleValuesChange = (changedValues) => {
    if (changedValues.operation) {
      form.resetFields(["mappingTemplate", "targetObject"]);
    }
    if (changedValues.targetObject) {
      form.resetFields(["mappingTemplate"]);
    }
  };

  return (
    <Box>
      <Form
        colon={false}
        labelAlign="left"
        wrapperCol={{ span: 15, offset: 1 }}
        labelCol={{ span: 8 }}
        form={form}
        onFinish={handleFinish}
        initialValues={{
          operation: Operation.Export,
        }}
        onValuesChange={handleValuesChange}
        component="div"
      >
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item
              label="Template type"
              rules={[{ required: true, message: "Field is mandatory." }]}
              name="type"
            >
              <Select
                placeholder="Select template type"
                options={toOptions(Object.values(TemplateType), getTemplateTypeLabel)}
                suffixIcon={<Icon component={DropdownArrow} />}
              />
            </Form.Item>
          </Col>
          {type === TemplateType.Mapping && (
            <Col span={12}>
              <Form.Item
                label="Operation"
                rules={[{ required: true, message: "Field is mandatory." }]}
                name="operation"
              >
                <Select
                  options={toOptions(Object.values(Operation), R.replace(/^./, R.toUpper))}
                  placeholder="Select operation"
                  suffixIcon={<Icon component={DropdownArrow} />}
                />
              </Form.Item>
            </Col>
          )}
          {type === TemplateType.Mapping && (
            <Col span={12}>
              <Form.Item
                label="Target Object"
                rules={[{ required: true, message: "Field is mandatory." }]}
                name="targetObject"
              >
                <Select
                  placeholder="Select target object"
                  options={toOptions(Object.values(OpenplayObjectType), capitalize)}
                  suffixIcon={<Icon component={DropdownArrow} />}
                />
              </Form.Item>
            </Col>
          )}
          {type === TemplateType.Mapping && targetObject && (
            <Col span={12}>
              <Form.Item
                label="Mapping Template"
                rules={[{ required: true, message: "Field is mandatory." }]}
                name="mappingTemplate"
              >
                <Select
                  options={mappingTemplatesToOptions(availableMappingTemplates)}
                  placeholder="Select template"
                  suffixIcon={<Icon component={DropdownArrow} />}
                />
              </Form.Item>
            </Col>
          )}
          {type === TemplateType.AssetNaming && (
            <Col span={12}>
              <Form.Item
                label="Asset Type"
                rules={[{ required: true, message: "Field is mandatory." }]}
                name="assetType"
              >
                <Select
                  options={toOptions(Object.values(AssetType))}
                  placeholder="Select asset type"
                  suffixIcon={<Icon component={DropdownArrow} />}
                />
              </Form.Item>
            </Col>
          )}
          {type === TemplateType.SpreadsheetColumnSet && (
            <Col span={12}>
              <Form.Item
                label="Source Object"
                rules={[{ required: true, message: "Field is mandatory." }]}
                name="sourceObject"
              >
                <SalesforceObjectPicker />
              </Form.Item>
            </Col>
          )}
          {type === TemplateType.SpreadsheetColumnSet && sourceObject && getOpenPlayEquivalent(sourceObject) && (
            <Col span={12}>
              <Form.Item
                label="Data Source"
                rules={[{ required: true, message: "Field is mandatory." }]}
                name="source"
                initialValue={TabSource.Salesforce}
              >
                <Select
                  options={toOptions(Object.values(TabSource), getTabSourceLabel)}
                  suffixIcon={<Icon component={DropdownArrow} />}
                />
              </Form.Item>
            </Col>
          )}
          <Col span={24}>
            <Button onClick={form.submit} className="ant-btn-primary">
              Create Template
            </Button>
          </Col>
        </Row>
      </Form>
    </Box>
  );
};
