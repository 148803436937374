import { Plus } from "../../../common/Icons";
import { FullWidthButton } from "../../../common/FullWidthButton/FullWidthButton";
import { FieldList, FieldListItem } from "../../../common/Form/FieldList";
import { Variation } from "./Variation";
import { useFormErrors } from "../../../common/Form/hooks";
import { EditableTabs } from "../../../common/EditableTabs";
import { Form } from "antd";
import { truncate } from "@/utils";

type Props = {
  name: string;
  disabled?: boolean;
};

type TabNameProps = {
  tabIndex: number;
};

const TabName = ({ tabIndex }: TabNameProps) => {
  const name = Form.useWatch(["variations", tabIndex, "name"]) ?? null;
  return name ? truncate(name, 15) : `Variation #${tabIndex + 1}`;
};

export const VariationList = ({ name, disabled }: Props) => {
  const { fieldsWithErrors } = useFormErrors();

  const invalidKeys = fieldsWithErrors
    .filter((field) => field.name[0] === "variations")
    .map((field) => field.name[1]);

  return (
    <FieldList name={name}>
      {(fields, { add, remove }) =>
        fields.length > 0 ? (
          <EditableTabs
            disabled={disabled}
            invalidKeys={invalidKeys}
            items={fields.map((field) => ({
              key: field.name,
              label: <TabName tabIndex={field.key} />,
              content: (
                <FieldListItem key={field.key} name={field.name}>
                  <Variation disabled={disabled} />
                </FieldListItem>
              ),
            }))}
            onAdd={() => add()}
            onRemove={(targetKey) => {
              remove(targetKey);
            }}
          />
        ) : (
          <FullWidthButton label="Add Variations" icon={Plus} onClick={() => add()} disabled={disabled} />
        )
      }
    </FieldList>
  );
};
