import { useParams } from "react-router-dom";
import { useRequest } from "ahooks";
import { fetchTemplate } from "@/api/templates";
import { Spinner } from "../../common/Spinner/Spinner";
import { TemplateForm } from "./TemplateForm";

export const TemplatePage = () => {
  const { id } = useParams();
  const { loading, data: template } = useRequest(() => fetchTemplate(id), { refreshDeps: [id] });

  if (loading) {
    return <Spinner />;
  }

  return <TemplateForm initialValues={template} />;
};
