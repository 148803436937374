import { Col, Form, Row } from "antd";
import { VariationList } from "./VariationList/VariationList";
import { SalesforceQueryList } from "@/components/Reports/Details/SalesforceQueryList/SalesforceQueryList";
import { ReportType } from "@/api/reports/types";

type Props = {
  readOnly?: boolean;
};

export const ReportQueries = ({ readOnly }: Props) => {
  const isSingleQueryReport = (Form.useWatch("type") ?? ReportType.SingleQuery) === ReportType.SingleQuery;
  const isMainQueryObjectSelected = !!Form.useWatch(["queries", 0, "from"]);

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <SalesforceQueryList
          name="queries"
          maxQueries={isSingleQueryReport ? 1 : undefined}
          disabled={readOnly}
          rules={[
            {
              validator: (_, value) =>
                value?.length > 0
                  ? Promise.resolve()
                  : Promise.reject(
                      isSingleQueryReport
                        ? "The query should be configured"
                        : "At least one query should be configured"
                    ),
            },
          ]}
        />
      </Col>
      {isSingleQueryReport && isMainQueryObjectSelected && (
        <Col span={24}>
          <VariationList name="variations" disabled={readOnly} />
        </Col>
      )}
    </Row>
  );
};
