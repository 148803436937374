import { useRequest } from "ahooks";
import fileDownload from "js-file-download";
import { useCallback } from "react";
import { openNotification } from "../components/common/CommonNotification";
import { parseFileName } from "./utils";

export function useDownload(request, { message }) {
  const { run, loading } = useRequest(request, {
    manual: true,
    onSuccess: (res) => {
      const fileName = parseFileName(res.headers["content-disposition"]);
      fileDownload(res.data, `${fileName}`, res.headers["content-type"]);
    },
  });
  const start = useCallback(
    (...args) => {
      openNotification({
        description: message,
      });
      run(...args).catch((e) => console.error(e));
    },
    [run, message]
  );

  return {
    start,
    loading,
  };
}
