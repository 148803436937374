import { FilterType } from "@/components/common/FilterInput/FilterInput";
import { toOptions } from "@/utils/toOptions";
import { getTemplateTypeLabel, TemplateType } from "@/utils";
import { fetchUsers } from "@/api/users";
import { fetchTemplates } from "@/api/templates";

export const filtersConfig = [
  {
    name: "name",
    label: "Template Name",
    type: FilterType.Autocomplete,
    getSuggestions: async (query) => {
      const response = await fetchTemplates({ name: query });
      return response.data.map((template) => template.name);
    },
  },
  {
    name: "type",
    label: "Template type",
    type: FilterType.Select,
    options: toOptions(Object.values(TemplateType), getTemplateTypeLabel),
  },
  { name: "description", label: "Description", type: FilterType.Text },
  {
    name: "updatedBy",
    label: "Last updated by",
    type: FilterType.Autocomplete,
    getSuggestions: async (query) => {
      const response = await fetchUsers({ search: query });
      return response.data.map((user) => user.name);
    },
  },
  { name: "created_at", label: "Updated At", type: FilterType.DateRange },
];
