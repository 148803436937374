import { QueryPicker } from "@/components/Reports/QueryPicker";
import { Field } from "@/components/common/Form/Field";
import { Form } from "antd";
import type { SpreadsheetColumn } from "@/api/spreadsheets/types";
import { useFieldPath } from "@/components/common/Form/hooks";
import { TabSource } from "@/utils";

export const QueryPickerField = () => {
  const { getAbsolutePath } = useFieldPath();
  const form = Form.useFormInstance();
  const columns = Form.useWatch<SpreadsheetColumn[]>(getAbsolutePath(["columnSet", "columns"])) ?? [];
  const isDisabled = columns.length > 0;

  const handleChange = () => {
    form.setFieldValue(getAbsolutePath("source"), TabSource.Salesforce);
  };

  return (
    <Field
      name="query"
      label="Query"
      rules={[{ required: true }]}
      tooltip={isDisabled ? "Query cannot be changed if one or more columns are added" : null}
    >
      <QueryPicker disabled={isDisabled} onChange={handleChange} />
    </Field>
  );
};
