import { api } from "../core";
import type {
  CalculatedFieldInfo,
  CreateSpreadsheetColumnSetDto,
  SpreadsheetColumnSet,
  SpreadsheetColumnSetFilters,
  UpdateSpreadsheetColumnSetDto
} from "./types";
import type { PaginatedResponse } from "@/api/types";
import { stripUndefined } from "@/utils";

const url = "/api/spreadsheet-column-sets";

export const getOpenplayCalculatedFields = () =>
  api.get<any, CalculatedFieldInfo[]>(`${url}/openplay/calculated-fields`);

export const fetchColumnSets = (params: SpreadsheetColumnSetFilters) =>
  api.get<any, PaginatedResponse<SpreadsheetColumnSet>>(url, { params });

export const fetchColumnSet = (id: string) => api.get<any, SpreadsheetColumnSet>(`${url}/${id}`);

export const createColumnSet = (fields: CreateSpreadsheetColumnSetDto) =>
  api.post<any, SpreadsheetColumnSet>(url, fields);

export const updateColumnSet = (fields: UpdateSpreadsheetColumnSetDto) =>
  api.put<any, SpreadsheetColumnSet>(url, fields);

export const deleteColumnSet = (id: string) => api.delete<any, void>(`${url}/${id}`);

export const saveColumnSet = (fields: CreateSpreadsheetColumnSetDto | UpdateSpreadsheetColumnSetDto) => {
  const cleanedUpFields = stripUndefined(fields);
  return "id" in cleanedUpFields ? updateColumnSet(cleanedUpFields) : createColumnSet(cleanedUpFields);
};

export const findColumnSetByName = (name: string) =>
  api.get<any, SpreadsheetColumnSet>(`${url}/find`, { params: { name } });
