import Icon from "@ant-design/icons";
import { DropdownArrow } from "@/components/common/Icons";
import { Form, Select, type SelectProps } from "antd";
import type { SalesforceQuery } from "@/api/reports/types";

type Props = Omit<SelectProps, "options">

export const QueryPicker = (props: Props) => {
  const queries = Form.useWatch<SalesforceQuery[]>("queries") ?? [];
  const options = queries.filter(entry => !!entry.from).map((entry, index) => ({ value: entry.id, label: entry.name ?? `Query #${index + 1}` }))

  return (
    <Select
      style={{ minWidth: 200 }}
      placeholder="Select Query"
      {...props}
      options={options}
      suffixIcon={<Icon component={DropdownArrow} />}
    />
  );
};
