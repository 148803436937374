import useSWR from "swr";
import type {
  CreateOpenplayQuery,
  OpenplayQueryFilters,
  UpdateOpenplayQuery,
} from "@/api/openplay-queries/types";
import {
  checkOpenplayQuery,
  fetchDatabaseSchema,
  fetchOpenplayQueries,
  fetchOpenplayQuery,
  saveOpenplayQuery,
} from "@/api/openplay-queries/index";
import useSWRMutation from "swr/mutation";
import useSWRImmutable from "swr/immutable";

const key = "openplay-queries";

export const useOpenplayQueries = (filters: OpenplayQueryFilters = {}) =>
  useSWR([key, filters], ([_, filters]) => fetchOpenplayQueries(filters), {
    fallbackData: { data: [] },
  });

export const useOpenplayQuery = (id?: string) =>
  useSWR(id ? [key, id] : null, ([, id]) => fetchOpenplayQuery(id), {
    fallbackData: null,
  });

export const useOpenplayQueryMutation = (id: string) =>
  useSWRMutation(
    id ? [key, id] : key,
    (_, { arg: dto }: { arg: CreateOpenplayQuery | UpdateOpenplayQuery }) => saveOpenplayQuery(dto)
  );

export const useOpenplayQueryStatus = () => {
  const { data, trigger, isMutating } = useSWRMutation("check-query", (_, { arg: sql }: { arg: string }) =>
    checkOpenplayQuery(sql)
  );

  return { status: data, refresh: trigger, refreshing: isMutating };
};

export const useOpenplayDatabaseSchema = () => useSWRImmutable("openplay-schema", fetchDatabaseSchema);
