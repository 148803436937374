import type { WebhookWithSettings } from "@/api/webhooks/types";
import { MessageType } from "@/api/webhooks/types";
import { Divider, Form, Input, PageHeader, Typography } from "antd";
import { getSalesforceMessageTypeLabel } from "@/utils/salesforce-message-type";
import { Summary } from "../../common/Summary/Summary";
import { useBoolean } from "ahooks";
import { useCallback, useState } from "react";
import { useNavigationConfirm } from "@/utils/useNavigationConfirm";
import { LabelMappings } from "./LabelMappings/LabelMappings";
import { openFormNotification } from "../../common/CommonNotification";
import { FormActions } from "../../common/FormActions";
import { WebhookActions } from "./WebhookActions";
import { FieldGroup } from "@/components/common/Form/FieldGroup";
import { Field } from "@/components/common/Form/Field";
import { updateWebhook } from "@/api/webhooks";
import { HiddenField } from "@/components/common/HiddenField";
import { Box } from "@/components/common/Box/Box";
import { useHistory } from "react-router-dom";
import { Markdown } from "@/components/Markdown";

const { Text } = Typography;
const { TextArea } = Input;

type Props = {
  initialValues: WebhookWithSettings;
};

export const WebhookForm = ({ initialValues }: Props) => {
  const { webhook } = initialValues;
  const history = useHistory();

  const [form] = Form.useForm();

  const [isEditing, { setFalse: disableEditing, setTrue: enableEditing }] = useBoolean();
  const [saving, setSaving] = useState(false);
  const { allowNavigation, preventNavigation } = useNavigationConfirm();

  const handleSave = async (values: typeof initialValues) => {
    const dto = { ...values };

    try {
      setSaving(true);
      await updateWebhook({
        messageType: dto.webhook.messageType,
        description: dto.description,
        labelMappings: dto.settings?.labelMappings,
      });
      openFormNotification("the webhook");
      allowNavigation();
      await history.push(`/webhooks`);
    } catch (e) {
      console.error("unhandled error", e);
    } finally {
      setSaving(false);
    }
  };

  const handleCancel = useCallback(() => {
    allowNavigation();
    form.resetFields();
    disableEditing();
  }, [form, disableEditing, allowNavigation]);

  const handleValueChange = useCallback(() => {
    if (isEditing) {
      preventNavigation();
    }
  }, [isEditing, preventNavigation]);

  return (
    <>
      <PageHeader
        title={
          <span style={{ fontWeight: "normal" }}>{getSalesforceMessageTypeLabel(webhook.messageType)}</span>
        }
        extra={
          <>
            <div style={{ marginRight: 16 }}>
              <Summary
                items={[
                  { label: "Object", value: webhook.object },
                  { label: "Endpoint", value: <Text code>{webhook.endpoint}</Text> },
                ]}
              />
            </div>
            {!isEditing && <WebhookActions webhook={webhook} />}
            <FormActions
              isEditing={isEditing}
              onEdit={enableEditing}
              isSaving={saving}
              onSave={form.submit}
              onCancel={handleCancel}
            />
          </>
        }
      />
      <Form
        form={form}
        onFinish={handleSave}
        initialValues={initialValues}
        colon={false}
        labelAlign="left"
        wrapperCol={{ span: 15, offset: 1 }}
        labelCol={{ span: 8 }}
        onValuesChange={handleValueChange}
        validateMessages={{
          required: "Field is mandatory",
        }}
      >
        <HiddenField name={["webhook", "messageType"]} />
        <Box readOnly={!isEditing}>
          <Field name="description" label="Description" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
            <TextArea placeholder="Description" rows={3} />
          </Field>
        </Box>
        {initialValues.webhook.messageType === MessageType.PlannerLabelcampProductCreation && (
          <>
            <Divider orientation="left">Label Mappings</Divider>
            <FieldGroup prefix="settings">
              <LabelMappings disabled={!isEditing} />
            </FieldGroup>
          </>
        )}
        {initialValues.details && (
          <>
            <Divider orientation="left">Details</Divider>
            <Typography>
              <Markdown markdown={initialValues.details} />
            </Typography>
          </>
        )}
      </Form>
    </>
  );
};
