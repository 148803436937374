import { StatusBadge } from "@/components/common/StatusBadge/StatusBadge";
import { truncate } from "@/utils";
import { BaseTag, type BaseTagProps } from "@/components/common/BaseTag/BaseTag";
import type { Report } from "@/api/reports/types";

interface Props extends Pick<BaseTagProps, "closable" | "onClose"> {
  report: Pick<Report, 'id' | 'name' | 'status'>;
}

export const ReportTag = ({ report, closable, onClose }: Props) => (
  <BaseTag key={report.id} closable={closable} onClose={onClose}>
    <StatusBadge status={report.status} text={truncate(report.name)} />
  </BaseTag>
);
