import { api } from "../core";
import {
  PARTNER_CREATE_URL,
  PARTNER_DEACTIVATION_CHECK_URL,
  PARTNER_DELIVERIES_URL,
  PARTNER_FIND_URL,
  PARTNER_LIST_URL,
  PARTNER_LOG_URL,
  PARTNER_URL,
} from "../urls";
import { isActive, isEmpty, isNotEmpty, Status } from "@/utils";

/**
 *
 * @param {Object} params
 * @returns {Promise<*>}
 */
export async function getPartners(params) {
  return api.get(PARTNER_LIST_URL, { params });
}

export const searchPartner = (paramsName = "search") => async (search) => {
  const partners = await api.get(PARTNER_LIST_URL, {
    params: { [paramsName]: search },
  });

  return partners.data.map((d) => ({ value: d.id, label: d.name }));
};

export async function getPartnerWithoutUsers(id) {
  return api.get(PARTNER_DEACTIVATION_CHECK_URL(id));
}

function preparePartnerData({ isActive, ...rest }) {
  const data = rest;
  if (typeof isActive !== "undefined") {
    data.status = isActive ? Status.Active : Status.Inactive;
  }
  if (isActive && isNotEmpty(data.deactivationReason)) {
    data.deactivationReason = "";
  }
  if (isEmpty(data.onHoldDays)) {
    data.onHoldDays = null;
  } else {
    data.onHoldDays = Number(data.onHoldDays);
  }
  return data;
}

export async function getPartner(id) {
  return api.get(PARTNER_URL(id)).then((data) => {
    return {
      ...data,
      isActive: isActive(data.status),
    };
  });
}

export async function createPartner(data) {
  return api.post(PARTNER_CREATE_URL, preparePartnerData(data));
}
export async function updatePartner(id, data) {
  return api.put(PARTNER_URL(id), preparePartnerData(data));
}

export function findPartner(params) {
  return api.get(PARTNER_FIND_URL, { params });
}

export function getDeliveriesByPartnerId(partnerId, params) {
  return api.get(PARTNER_DELIVERIES_URL(partnerId), { params });
}

export async function downloadPartnerLog(partnerId) {
  return api.get(PARTNER_LOG_URL(partnerId), {
    responseType: "blob",
  });
}
