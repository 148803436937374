import { Box } from "@/components/common/Box/Box";
import { ReportType } from "@/api/reports/types";
import { Button, Col, Form, Row, Select } from "antd";
import Icon from "@ant-design/icons";
import { DropdownArrow } from "@/components/common/Icons";
import { toOptions } from "@/utils/toOptions";
import { getReportTypeLabel } from "@/utils/report-type";

type FormValues = { type: ReportType };

type Props = {
  onSelect: (values: FormValues) => void;
};

export const ReportTypeSelector = ({ onSelect }: Props) => {
  const [form] = Form.useForm<FormValues>();

  const handleFinish = (values: FormValues) => {
    onSelect(values);
  };

  return (
    <Box>
      <Form
        colon={false}
        labelAlign="left"
        wrapperCol={{ span: 15, offset: 1 }}
        labelCol={{ span: 8 }}
        form={form}
        onFinish={handleFinish}
        component="div"
      >
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item
              label="Type"
              rules={[{ required: true, message: "Field is mandatory." }]}
              name={["type"]}
            >
              <Select
                style={{ minWidth: 200 }}
                placeholder="Select Report Type"
                options={toOptions(Object.values(ReportType), getReportTypeLabel)}
                suffixIcon={<Icon component={DropdownArrow} />}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Button onClick={form.submit} className="ant-btn-primary">
              Create Report
            </Button>
          </Col>
        </Row>
      </Form>
    </Box>
  );
};
