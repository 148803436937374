import { useRequest } from "ahooks";
import PropTypes from "prop-types";
import { useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { fetchFeeds } from "@/api/feeds";
import {
  capitalize,
  FeedScheduleStatus,
  FeedTab,
  getFeedScheduleStatusLabel,
  getStatusLabel,
  prepareParams,
  statuses,
} from "@/utils";
import { TableWithPaginationAndSorting } from "../../common";
import { columns } from "./columns";
import { useGridParams } from "@/utils/useGridParams";
import FilterableContent from "@/components/Layout/FilterableContent";
import { FilterType } from "@/components/common/FilterInput/FilterInput";
import { toOptions } from "@/utils/toOptions";
import { OpenplayObjectType, Operation } from "@/types/common";

export function FeedsList({ partnerId }) {
  const { filters, pagination, sort } = useGridParams();
  const history = useHistory();

  const handleEdit = useCallback(
    ({ id }) => history.push(`/partners/${partnerId}/feeds/${id}/${FeedTab.Summary}`),
    [history, partnerId]
  );

  const filtersConfig = useMemo(
    () => [
      {
        name: "name",
        label: "Name",
        type: FilterType.Autocomplete,
        getSuggestions: async (query) => {
          const response = await fetchFeeds(partnerId, { name: query });
          return response.data.map((feed) => feed.name);
        },
      },
      { name: "description", label: "Description", type: FilterType.Text },
      {
        name: "status",
        label: "Status",
        type: FilterType.Select,
        options: toOptions(statuses.map(String), getStatusLabel),
      },
      {
        name: "operation",
        label: "Operation",
        type: FilterType.Radio,
        options: toOptions(Object.values(Operation), capitalize),
      },
      {
        name: "targetObject",
        label: "Target Object",
        type: FilterType.Radio,
        options: toOptions(Object.values(OpenplayObjectType), capitalize),
      },
      {
        name: "scheduled",
        label: "Scheduled",
        type: FilterType.Select,
        options: toOptions(Object.values(FeedScheduleStatus), getFeedScheduleStatusLabel),
      },
    ],
    [partnerId]
  );

  const { data, loading } = useRequest(
    () => fetchFeeds(partnerId, prepareParams(filters, pagination, sort)),
    {
      refreshDeps: [partnerId, filters, pagination, sort],
      debounceInterval: 100,
    }
  );

  return (
    <FilterableContent formConfig={filtersConfig} filterName="feeds-filter" isFiltersConfigurable={false}>
      <TableWithPaginationAndSorting
        rowKey="id"
        onRowClick={handleEdit}
        columns={columns}
        data={data}
        loading={loading}
        scroll={{ x: true }}
      />
    </FilterableContent>
  );
}

FeedsList.propTypes = {
  partnerId: PropTypes.string.isRequired,
};
