import { PageHeader, Popconfirm, Space, Table } from "antd";
import { useRequest } from "ahooks";
import { deleteSalesforceObject, importSalesforceObject } from "@/api/salesforce-objects";
import { ImportObjectButton } from "@/components/SalesforceObjects/List/ImportObjectButton";
import type { ColumnsType } from "antd/lib/table/interface";
import type { SalesforceObject } from "@/api/salesforce-objects/types";
import { DATETIME_FORMATTER } from "@/utils";
import { IconButton } from "@/components/common/IconButton/IconButton";
import { Refresh, Remove } from "@/components/common/Icons";
import { useCallback, useMemo } from "react";
import { useSalesforceObjects } from "@/api/salesforce-objects/hooks";

export const SalesforceObjectList = () => {
  const { data: response, isLoading: loading, mutate } = useSalesforceObjects();

  const { run: startDeletion, loading: deleting } = useRequest(deleteSalesforceObject, {
    manual: true,
  });

  const { run: startImporting, loading: importing } = useRequest(importSalesforceObject, {
    manual: true,
  });

  const handleRemove = useCallback(
    async (name: string) => {
      await startDeletion(name);
      await mutate();
    },
    [mutate, startDeletion]
  );

  const handleRefresh = useCallback(
    async (name: string) => {
      await startImporting(name);
      await mutate();
    },
    [mutate, startImporting]
  );

  const columns: ColumnsType<SalesforceObject> = useMemo(
    () => [
      {
        title: "Name",
        dataIndex: "name",
      },
      {
        title: "Label",
        dataIndex: "label",
      },
      {
        title: "Updated At",
        dataIndex: "updatedAt",
        render: (_, so) => DATETIME_FORMATTER.format(new Date(so.updatedAt)),
      },
      {
        title: "Actions",
        render: (_, { name, label }) => (
          <Space>
            <IconButton icon={Refresh} onClick={() => handleRefresh(name)} />
            <Popconfirm
              title={`Are you sure you want to remove '${label}' object?`}
              onConfirm={() => handleRemove(name)}
            >
              <IconButton icon={Remove} />
            </Popconfirm>
          </Space>
        ),
      },
    ],
    [handleRefresh, handleRemove]
  );

  return (
    <>
      <PageHeader
        style={{ marginBottom: 16 }}
        title={<h1 style={{ margin: 0 }}>Salesforce Objects</h1>}
        extra={[<ImportObjectButton key="import" onFinish={mutate} />]}
      />
      <Table
        rowKey="id"
        dataSource={response.data}
        columns={columns}
        loading={loading || deleting || importing}
        pagination={false}
      />
    </>
  );
};
