import { Button, Col, Form } from "antd";
import type { SalesforceQuery } from "@/api/reports/types";
import { ReportType } from "@/api/reports/types";
import { Field } from "@/components/common/Form/Field";
import { QueryPicker } from "@/components/Reports/QueryPicker";
import { useFieldPath } from "@/components/common/Form/hooks";
import { FieldList, FieldListItem } from "@/components/common/Form/FieldList";
import Icon from "@ant-design/icons";
import { Plus } from "@/components/common/Icons";
import { FieldToUpdate } from "@/components/Reports/Details/ActionsList/FieldToUpdate/FieldToUpdate";

const useSourceObject = () => {
  const { getAbsolutePath } = useFieldPath();
  const reportType = Form.useWatch<ReportType>("type");
  const queries = Form.useWatch<SalesforceQuery[]>("queries");
  const queryId = Form.useWatch<string>(getAbsolutePath("queryId"));
  return reportType === ReportType.SingleQuery
    ? queries?.[0]?.from
    : queries?.find((query) => query.id === queryId)?.from;
};

export const UpdateRecordsActionFields = () => {
  const reportType = Form.useWatch<ReportType>("type");
  const sourceObject = useSourceObject();
  return (
    <>
      {reportType === ReportType.MultipleQuery && (
        <Col span={12}>
          <Field label="Query" name="queryId" rules={[{ required: true, message: "Please, select query" }]}>
            <QueryPicker />
          </Field>
        </Col>
      )}
      <Col span={24}>
        <FieldList name="fields">
          {(fields, { add, remove }) => (
            <div className="flex-col">
              {fields.map((field) => (
                <FieldListItem key={field.key} name={field.name}>
                  <FieldToUpdate sourceObject={sourceObject} onRemove={() => remove(field.name)} />
                </FieldListItem>
              ))}
              <Button
                size="small"
                onClick={() => add()}
                icon={<Icon component={Plus} />}
                style={{ alignSelf: "flex-start" }}
              >
                Add Field
              </Button>
            </div>
          )}
        </FieldList>
      </Col>
    </>
  );
};
