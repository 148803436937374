import type { PropsWithChildren } from "react";
import { createContext, useContext } from "react";

export type SpreadsheetFormContextType = {
  entity: "report" | "email-alert";
};

const SpreadsheetFormContext = createContext<SpreadsheetFormContextType>({ entity: "report" });

export const SpreadsheetFormProvider = ({
  children,
  ...value
}: PropsWithChildren<SpreadsheetFormContextType>) => (
  <SpreadsheetFormContext.Provider children={children} value={value} />
);

export const useSpreadsheetFormContext = () => useContext(SpreadsheetFormContext);
