import useSWR, { useSWRConfig } from "swr";
import {
  fetchAvailableFlowEvents,
  fetchScript,
  fetchScripts,
  pullCustomEvent,
  updateScript,
} from "@/api/flows/index";
import useSWRMutation from "swr/mutation";
import type { ImportPlatformEventParams, ScriptDescriptionUpdateDto, ScriptFilters } from "@/api/flows/types";

const key = "flow-events";

export const useFlowEvents = () =>
  useSWR(key, fetchAvailableFlowEvents, {
    fallbackData: [],
  });

export const useFlowEventImport = () => {
  const { mutate } = useSWRConfig();
  return useSWRMutation(key, async (_, { arg: params }: { arg: ImportPlatformEventParams }) => {
    await pullCustomEvent(params);
    await mutate(["salesforce-object", params.name]);
  });
};

export const useScripts = (filters: ScriptFilters = {}) =>
  useSWR(["scripts", filters], ([_, filters]) => fetchScripts(filters), {
    fallbackData: [],
  });

export const useScript = (key: string) => useSWR(["scripts", key], ([_, key]) => fetchScript(key));

export const useScriptMutation = (key: string) =>
  useSWRMutation(key ? ["scripts", key] : "scripts", (_, { arg: dto }: { arg: ScriptDescriptionUpdateDto }) =>
    updateScript(dto)
  );
