import { useParams } from "react-router-dom";
import { useRequest } from "ahooks";
import { getPartner } from "@/api/partners";
import { Spinner } from "../../common/Spinner/Spinner";
import { PartnerForm } from "./PartnerForm";

export const Partner = () => {
  const { partnerId } = useParams();

  const { loading, data: partner } = useRequest(() => getPartner(partnerId), {
    initialData: {},
    cacheKey: `partner-${partnerId}`,
  });

  if (loading) {
    return <Spinner />;
  }

  return <PartnerForm defaultValues={partner} />;
};
