import { FieldList, FieldListItem } from "@/components/common/Form/FieldList";
import type { ComponentProps } from "react";
import { SortableList, SortableListItem } from "@/components/common/SortableList";
import { ReportAction } from "./ReportAction";
import { Plus } from "@/components/common/Icons";
import { FullWidthButton } from "@/components/common/FullWidthButton/FullWidthButton";
import { ReportActionType } from "@/api/reports/types";

type Props = Pick<ComponentProps<typeof FieldList>, "name">;

export const ReportActionList = ({ name }: Props) => {
  return (
    <FieldList name={name}>
      {(fields, { add, move, remove }) => (
        <div className="flex-col">
          <SortableList onMove={move}>
            {fields.map((field) => (
              <SortableListItem key={field.key} index={field.name} onRemove={() => remove(field.name)}>
                <FieldListItem name={field.name}>
                  <ReportAction />
                </FieldListItem>
              </SortableListItem>
            ))}
          </SortableList>
          <FullWidthButton
            small
            label="Add Action"
            icon={Plus}
            onClick={() => add({ type: ReportActionType.UpdateRecords, fields: [] })}
          />
        </div>
      )}
    </FieldList>
  );
};
