import type { SelectProps } from "antd";
import { Select } from "antd";
import Icon from "@ant-design/icons";
import { DropdownArrow } from "@/components/common/Icons";
import { useSalesforceObjects } from "@/api/salesforce-objects/hooks";

export const SalesforceObjectPicker = (props: Omit<SelectProps, "options">) => {
  const {
    data: { data: entries },
    isLoading,
  } = useSalesforceObjects();

  return (
    <Select
      style={{ minWidth: 200 }}
      placeholder="Select Salesforce Object"
      {...props}
      loading={isLoading}
      options={entries.map((entry) => ({ value: entry.name, label: entry.label }))}
      suffixIcon={<Icon component={DropdownArrow} />}
    />
  );
};
