import { useParams } from "react-router-dom";
import { useRequest } from "ahooks";
import { Spinner } from "@/components/common/Spinner/Spinner";
import { fetchFlow } from "@/api/flows";
import { FlowForm } from "@/components/Flows/Details/FlowForm";

export const FlowPage = () => {
  const { id } = useParams<{ id: string }>();

  const { data: flow, loading } = useRequest(() => fetchFlow(id), {
    refreshDeps: [id],
  });

  if (loading) {
    return <Spinner />;
  }

  return <FlowForm initialValues={flow} />;
};
