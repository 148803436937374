import { Alert, Button, Col, Form, Input, PageHeader, Popconfirm, Row, Space, Spin } from "antd";
import { useHistory } from "react-router-dom";
import { useNavigationConfirm } from "@/utils/useNavigationConfirm";
import { useBoolean } from "ahooks";
import { FormActions } from "@/components/common/FormActions";
import { Box } from "@/components/common/Box/Box";
import { isUniqueName } from "@/utils/validation";
import { Field } from "@/components/common/Form/Field";
import { useEffect } from "react";
import type { CreateOpenplayQuery, OpenplayQuery, UpdateOpenplayQuery } from "@/api/openplay-queries/types";
import { useOpenplayQueryMutation, useOpenplayQueryStatus } from "@/api/openplay-queries/hooks";
import { checkOpenplayQuery, deleteOpenplayQuery, findOpenplayQueryByName } from "@/api/openplay-queries";
import { HiddenField } from "@/components/common/HiddenField";
import { capitalize } from "@/utils";
import { OpenplaySQLEditor } from "@/components/OpenplayQueries/Details/OpenplaySQLEditor";

const { TextArea } = Input;

type Props = {
  initialValues?: Partial<OpenplayQuery>;
  isEditingByDefault?: boolean;
};

type FormValues = CreateOpenplayQuery | UpdateOpenplayQuery;

export const OpenplayQueryForm = ({ initialValues = {}, isEditingByDefault = false }: Props) => {
  const [form] = Form.useForm<FormValues>();

  const history = useHistory();
  const { allowNavigation, preventNavigation } = useNavigationConfirm();

  const [editing, { setTrue: enableEditing, setFalse: disableEditing }] = useBoolean(isEditingByDefault);

  const title = initialValues.name ?? "New OpenPlay Query";
  const isDeletable = !!initialValues.id && !editing;

  const { trigger: save, isMutating: saving } = useOpenplayQueryMutation(initialValues.id);

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [form, initialValues]);

  const handleSave = async (values: FormValues) => {
    try {
      await save(values);
      allowNavigation();
      await history.push(`/openplay-queries`);
    } catch (e) {
      console.error("unhandled error", e);
    }
  };

  const handleFinish = async (values: FormValues) => {
    await handleSave(values);
  };

  const handleDelete = async () => {
    if (initialValues.id) {
      await deleteOpenplayQuery(initialValues.id);
      history.push(`/openplay-queries`);
    }
  };

  const handleCancel = () => {
    allowNavigation();
    if (initialValues.id) {
      form.setFieldsValue(initialValues);
      disableEditing();
    } else {
      history.push(`/openplay-queries`);
    }
  };

  const handleValueChange = () => {
    if (editing) {
      preventNavigation();
    }
  };

  const handleEdit = () => {
    enableEditing();
  };

  const { status, refreshing, refresh } = useOpenplayQueryStatus();

  const sql = Form.useWatch("sql", form);

  const canCheckQuery = !!sql?.trim();

  const handleCheckQuery = () => {
    return refresh(sql);
  };

  return (
    <>
      <PageHeader
        title={<h1 style={{ margin: 0 }}>{title}</h1>}
        extra={
          <>
            <FormActions
              isEditing={editing}
              onSave={form.submit}
              isSaving={saving}
              onEdit={handleEdit}
              onCancel={handleCancel}
            />
            {isDeletable && (
              <Popconfirm
                title="Are you sure to delete the OpenPlay query?"
                onConfirm={handleDelete}
                okText="Yes"
                cancelText="No"
              >
                <Button>Delete</Button>
              </Popconfirm>
            )}
          </>
        }
      />
      <Form form={form} onFinish={handleFinish} onValuesChange={handleValueChange} labelAlign="left">
        <HiddenField name="id" />
        <Box readOnly={!editing}>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Field
                name="name"
                label="Name"
                validateTrigger={["onChange", "onBlur"]}
                rules={[
                  { required: true, message: "Please, enter a name for the query" },
                  isUniqueName(
                    findOpenplayQueryByName,
                    "A query with entered name already exists in the system"
                  ),
                ]}
              >
                <Input placeholder="Name" />
              </Field>
            </Col>
            <Col span={12}>
              <Field name="description" label="Description">
                <TextArea placeholder="Description" rows={2} />
              </Field>
            </Col>
            <Col span={24}>
              <Form.Item label="SQL" wrapperCol={{ span: 24 }} labelCol={{ span: 24 }}>
                <Space direction="vertical" style={{ width: "100%" }}>
                  {status && (
                    <Spin spinning={refreshing}>
                      <Alert
                        type={status.success ? "success" : "error"}
                        message={
                          status.success === true
                            ? status.count > 0
                              ? `Query is correct. ${status.count} row${status.count === 1 ? "" : "s"} found.`
                              : "Query is correct, but no rows found."
                            : `Query has an error: ${capitalize(status.reason)}. ` +
                              `Please review your SQL syntax.`
                        }
                      />
                    </Spin>
                  )}
                  <Field
                    name="sql"
                    validateFirst
                    rules={[
                      {
                        required: true,
                        message: "Please, provide an SQL query",
                        transform: (value) => value.trim(),
                      },
                      {
                        validator: async (_: any, value: string) => {
                          const status = await checkOpenplayQuery(value);
                          if (status.success === true) {
                            return Promise.resolve();
                          } else {
                            return Promise.reject(status.reason);
                          }
                        },
                        validateTrigger: ["onSubmit"],
                      },
                    ]}
                    validateTrigger={["onChange", "onSubmit"]}
                  >
                    <OpenplaySQLEditor placeholder="SELECT id FROM ..." readOnly={!editing} />
                  </Field>
                  <Button
                    size="small"
                    loading={refreshing}
                    onClick={handleCheckQuery}
                    disabled={!canCheckQuery}
                  >
                    Check Query
                  </Button>
                </Space>
              </Form.Item>
            </Col>
          </Row>
        </Box>
      </Form>
    </>
  );
};
