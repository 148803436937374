import { useParams } from "react-router-dom";
import { useRequest } from "ahooks";
import { fetchWebhook } from "../../../api/webhooks";
import type { MessageType } from "../../../api/webhooks/types";
import { WebhookForm } from "./WebhookForm";
import { Spinner } from "../../common/Spinner/Spinner";

export const WebhookPage = () => {
  const { type } = useParams<{ type: MessageType }>();

  const { data: webhookWithSettings, loading } = useRequest(() => fetchWebhook(type), {
    refreshDeps: [type],
  });

  if (loading) {
    return <Spinner />;
  }

  return <WebhookForm initialValues={webhookWithSettings} />;
};
