import React from "react";
import { useParams } from "react-router-dom";
import { fetchFeed } from "../../../api/feeds";
import { FeedForm } from "./FeedForm";
import { useRequest } from "ahooks";
import { Spinner } from "../../common/Spinner/Spinner";

export function FeedPage() {
  const { feedId } = useParams();
  const { loading, data: feed } = useRequest(() => fetchFeed(feedId), { initialData: {} });

  if (loading) {
    return <Spinner />;
  }

  return <FeedForm initialValues={feed} />;
}
