import { Alert, Button, Col, Form, Input, Popconfirm, Row, Space } from "antd";
import { Field } from "@/components/common/Form/Field";
import { ColumnSetPicker } from "@/components/Reports/Details/SpreadsheetTabs/SpreadsheetTab/ColumnSetForm/ColumnSetPicker";
import { ReportType } from "@/api/reports/types";
import { SpreadsheetColumnList } from "@/components/Spreadsheets/SpreadsheetColumnList";
import { FieldGroup } from "@/components/common/Form/FieldGroup";
import type { TabSource } from "@/utils";
import { useBoolean } from "ahooks";
import { useFieldPath } from "@/components/common/Form/hooks";
import type { SpreadsheetColumnSet } from "@/api/spreadsheets/types";
import { useSpreadsheetFormContext } from "@/components/Spreadsheets/SpreadsheetForm/context";

type Props = {
  sourceObject: string;
  tabSource: TabSource;
};

const MissingSourceObjectWarning = () => {
  const { entity } = useSpreadsheetFormContext();
  const reportType = Form.useWatch<ReportType>("type");

  if (entity === "email-alert") {
    return null;
  }

  return (
    <Alert
      type="warning"
      message={
        reportType === ReportType.SingleQuery
          ? "Please, set up the query to be able add columns"
          : "Please, set up and select the query to be able to add columns"
      }
    />
  );
};

export const ColumnSetForm = ({ sourceObject, tabSource }: Props) => {
  const { getAbsolutePath } = useFieldPath();

  const form = Form.useFormInstance();
  const id = Form.useWatch<string>(getAbsolutePath(["columnSet", "id"]));

  const canAddColumns = !!sourceObject && !!tabSource;

  const [editing, { setTrue: enableEditing, setFalse: disableEditing }] = useBoolean();

  const selected = !!id;
  const editable = selected && !editing;

  const handleCreate = () => {
    form.setFieldValue(getAbsolutePath("columnSet"), { columns: [] });
    enableEditing();
  };

  const handleReset = () => {
    form.resetFields([getAbsolutePath("columnSet"), getAbsolutePath("source")]);
    disableEditing();
  };

  const handleEdit = () => {
    enableEditing();
  };

  const handleDuplicate = () => {
    form.setFieldValue(getAbsolutePath(["columnSet", "id"]), undefined);
    enableEditing();
  };

  const handleSelect = (_, entry: SpreadsheetColumnSet) => {
    form.setFieldValue(getAbsolutePath("columnSet"), entry);
  };

  return (
    <FieldGroup prefix="columnSet">
      <Row gutter={[16, 16]}>
        {selected && (
          <Col span={24}>
            <Alert
              showIcon
              message={
                <>
                  Editing existing column set <strong>may impact</strong> other reports or email alerts that
                  reference it. If you want to use it as a starting point, consider using{" "}
                  <strong>Duplicate & Edit</strong>.
                </>
              }
            />
          </Col>
        )}
        {canAddColumns && (
          <Col span={24}>
            <Space size={8}>
              <Field name="id">
                <ColumnSetPicker
                  filters={{ sourceObject, source: tabSource }}
                  disabled={editing}
                  onChange={handleSelect}
                />
              </Field>
              {editable && (
                <>
                  <Popconfirm
                    title="Editing existing column set may impact other reports or email alerts that reference it"
                    okText="Edit Anyway"
                    onConfirm={handleEdit}
                    overlayStyle={{ maxWidth: 300 }}
                  >
                    <Button size="small">Edit</Button>
                  </Popconfirm>
                  <Button size="small" onClick={handleDuplicate}>
                    Duplicate & Edit
                  </Button>
                </>
              )}
              {editing && (
                <Button size="small" onClick={handleReset}>
                  Reset
                </Button>
              )}
              {!editing && (
                <Button size="small" type="primary" onClick={handleCreate}>
                  {selected ? "Reset & Create New" : "Create"}
                </Button>
              )}
            </Space>
          </Col>
        )}
        {canAddColumns && (
          <Col span={12}>
            <Field
              name="name"
              label="Name"
              rules={[
                {
                  required: true,
                  transform: (value) => value?.trim(),
                  message: "Field is mandatory",
                },
              ]}
              validateFirst
            >
              <Input placeholder="Column Set Name" disabled={!editing} />
            </Field>
          </Col>
        )}
        {!canAddColumns && (
          <Col span={24}>
            <MissingSourceObjectWarning />
          </Col>
        )}
        <Col span={24}>
          <SpreadsheetColumnList
            sourceObject={sourceObject}
            tabSource={tabSource}
            canAdd={canAddColumns}
            disabled={!editing}
          />
        </Col>
      </Row>
    </FieldGroup>
  );
};
