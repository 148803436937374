import { Cascader } from "antd";
import Icon from "@ant-design/icons";
import { DropdownArrow } from "@/components/common/Icons";
import { sortCascaderSearchResults } from "@/utils/autocomplete";
import { useSalesforceObject } from "@/api/salesforce-objects/hooks";
import { mapFieldsToOptions } from "./utils";
import type { FieldType } from "@/types/salesforce";

type Props = {
  value?: string[];
  onChange?: (value: string[]) => void;
  obj: string;
  excludeFieldTypes?: FieldType[];
  includeFieldTypes?: FieldType[];
  nesting?: number;
  updatableOnly?: boolean;
};

export const SalesforceObjectPathPicker = ({
  obj,
  value,
  onChange,
  excludeFieldTypes = [],
  includeFieldTypes = [],
  nesting = 2,
  updatableOnly = false,
}: Props) => {
  const { obj: salesforceObject, isLoading } = useSalesforceObject(obj);

  const options = salesforceObject
    ? mapFieldsToOptions({
        obj: salesforceObject,
        nesting,
        excludeFieldTypes,
        includeFieldTypes,
        updatableOnly,
      })
    : [];

  return (
    <Cascader
      value={value}
      onChange={onChange}
      placeholder="Field"
      options={options}
      suffixIcon={<Icon component={DropdownArrow} />}
      disabled={options.length === 0}
      showSearch={{ limit: 200, sort: sortCascaderSearchResults }}
      placement="bottomLeft"
      loading={isLoading}
    />
  );
};
