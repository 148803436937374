import type { ColumnsType } from "antd/lib/table/interface";
import type { SpreadsheetColumnSet } from "@/api/spreadsheets/types";
import { getTabSourceLabel, truncate } from "@/utils";
import type { Report } from "@/api/reports/types";
import { uniqBy } from "ramda";
import { ReportTag } from "@/components/Reports/ReportTag";
import { Space } from "antd";
import type { EmailAlert } from "@/api/email-alerts/types";
import { BaseTag } from "@/components/common/BaseTag/BaseTag";

type ColumnSetListItem = SpreadsheetColumnSet & {
  tabs: {
    spreadsheet: { report: Pick<Report, "id" | "name" | "status">; alert: Pick<EmailAlert, "id" | "name"> };
  }[];
};

export const columns: ColumnsType<ColumnSetListItem> = [
  {
    title: "Name",
    dataIndex: "name",
    sorter: true,
  },
  {
    title: "Source Object",
    dataIndex: ["sourceObject", "label"],
    sorter: true,
  },
  {
    title: "Source",
    dataIndex: "source",
    sorter: true,
    render: (_, entry) => getTabSourceLabel(entry.source),
  },
  {
    title: "Reports",
    key: "reports",
    render: (_, entry) => {
      const reports = uniqBy(
        (report) => report.id,
        entry.tabs.map((tab) => tab.spreadsheet.report).filter(Boolean)
      );

      return (
        <Space size={8} wrap>
          {reports.map((report) => (
            <ReportTag key={report.id} report={report} />
          ))}
        </Space>
      );
    },
  },
  {
    title: "Alerts",
    key: "alerts",
    render: (_, entry) => {
      const alerts = uniqBy(
        (alert) => alert.id,
        entry.tabs.map((tab) => tab.spreadsheet.alert).filter(Boolean)
      );

      return (
        <Space size={8} wrap>
          {alerts.map((alert) => (
            <BaseTag>{truncate(alert.name)}</BaseTag>
          ))}
        </Space>
      );
    },
  },
];
