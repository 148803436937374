import type { RecurrenceInterval, Recurrency } from "@/api/types";
import type { ColumnType } from "antd/lib/table/interface";
import { Tag } from "@/components/common/Tag/Tag";
import { getRecurrencyDescription } from "@/utils/cron";
import { Color } from "@/utils";
import { getTimezoneDescription } from "@/utils/timezones";

export const getScheduleColumns = <RecordType extends Record<string, unknown>>(
  getValue: (entry: RecordType) => Recurrency,
  fieldName: string,
  renderIntervalTag = (interval: RecurrenceInterval) => <Tag color={Color.SecondaryAccent}>{interval}</Tag>
): ColumnType<RecordType>[] => [
  {
    title: "Schedule",
    dataIndex: [fieldName, "interval"],
    sorter: true,
    colSpan: 2,
    align: "left",
    key: "schedule-tag",
    render: (_, entry) => renderIntervalTag(getValue(entry).interval),
    onCell: () => ({ style: { paddingRight: 4, whiteSpace: "nowrap", width: 1 } }),
  },
  {
    title: "Schedule",
    colSpan: 0,
    key: "schedule-desc",
    render: (_, entry) => {
      const description = getRecurrencyDescription(getValue(entry));
      if (!description) {
        return "-";
      }
      const tz = getTimezoneDescription(getValue(entry).timezone);
      return `${description} (${tz})`;
    },
    onCell: () => ({ style: { paddingLeft: 4, whiteSpace: "normal", wordBreak: "normal" } }),
  },
];
