import { Button, Form, Input, Modal, Space } from "antd";
import { Field } from "@/components/common/Form/Field";
import { useBoolean, useRequest } from "ahooks";
import { importSalesforceObject } from "@/api/salesforce-objects";

type Props = {
  onFinish: () => void;
  onCancel?: () => void;
};

type FormValues = { name: string };

export const ImportObjectButton = ({ onCancel, onFinish }: Props) => {
  const [isOpen, { setTrue: open, setFalse: close }] = useBoolean(false);
  const [form] = Form.useForm<FormValues>();

  const { loading, run: startImporting } = useRequest(importSalesforceObject, {
    manual: true,
    throwOnError: true,
  });

  const handleFinish = async ({ name }: FormValues) => {
    try {
      await startImporting(name.trim());
      form.resetFields();
      onFinish();
      close();
    } catch (error) {
      form.setFields([{ name: "name", errors: [error.message] }]);
    }
  };

  const handleCancel = () => {
    onCancel?.();
    close();
  };

  return (
    <>
      <Button onClick={open} type="primary">
        Import Salesforce Object
      </Button>
      <Modal
        title="Import Salesforce Object"
        open={isOpen}
        footer={
          <Space>
            <Button key="cancel" onClick={handleCancel}>
              Cancel
            </Button>
            <Button key="submit" type="primary" onClick={form.submit} loading={loading}>
              Import
            </Button>
          </Space>
        }
        onCancel={handleCancel}
      >
        <Form form={form} onFinish={handleFinish}>
          <Field
            name="name"
            rules={[{ required: true, message: "Field is mandatory", transform: (value) => value?.trim() }]}
          >
            <Input placeholder="Object Name" />
          </Field>
        </Form>
      </Modal>
    </>
  );
};
