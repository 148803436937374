import { Box } from "../../../common/Box/Box";
import { Col, Divider, Form, Input, Row } from "antd";
import { FieldGroup } from "../../../common/Form/FieldGroup";
import { RecipientList } from "@/components/common/EmailMessageForm";
import { VariationVariables } from "@/components/common/VariationVariables";
import { getFieldMaxLengthRule, requiredIfNotDraft } from "@/utils/validation";
import { SalesforceQueryBuilder } from "@/components/common/SalesforceQuery/SalesforceQueryBuilder";
import { Field } from "@/components/common/Form/Field";

type Props = {
  disabled?: boolean;
};

export const Variation = ({ disabled }: Props) => {
  const from = Form.useWatch<string>(["queries", 0, "from"]) ?? null;

  return (
    <Box readOnly={disabled}>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Field name="name" label="Name" rules={[getFieldMaxLengthRule("Variation name", 30)]}>
            <Input />
          </Field>
        </Col>
        <Col span={24}>
          <FieldGroup prefix="query">
            <Field
              key={from}
              name="where"
              wrapperCol={{ span: 24 }}
              rules={[
                (form) => ({
                  ...requiredIfNotDraft(form),
                  message: "At least one of the rules should be configured",
                }),
              ]}
            >
              <SalesforceQueryBuilder from={from} />
            </Field>
          </FieldGroup>
        </Col>
        <Col span={24}>
          <Form.Item label="To" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
            <RecipientList name={["email", "to"]} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label="CC" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
            <RecipientList name={["email", "cc"]} />
          </Form.Item>
        </Col>
      </Row>
      <Divider orientation="left">Variables</Divider>
      <VariationVariables />
    </Box>
  );
};
