import { Button, PageHeader } from "antd";
import { prepareParams } from "@/utils";
import { TableWithPaginationAndSorting } from "../../common";
import { columns } from "./columns";
import Icon from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import "./Users.less";
import { Plus } from "../../common/Icons";
import { useGridParams } from "@/utils/useGridParams";
import { useUsers } from "@/api/users/hooks";

export const UsersList = () => {
  const { pagination, sort, filters } = useGridParams();
  const history = useHistory();

  const handleCreate = () => history.push("/users/new");
  const handleEdit = ({ id }) => history.push(`/users/${id}`);

  const { data, isLoading } = useUsers(prepareParams(filters, pagination, sort));

  return (
    <>
      <PageHeader
        backIcon={false}
        className="site-page-header"
        onBack={() => null}
        title={<h1>Users</h1>}
        extra={[
          <Button key="new-User" type="primary" onClick={handleCreate} icon={<Icon component={Plus} />}>
            New User
          </Button>,
        ]}
      />

      <TableWithPaginationAndSorting
        rowKey="id"
        onRowClick={handleEdit}
        columns={columns}
        data={data}
        loading={isLoading}
        className={"users-table-wrapper"}
      />
    </>
  );
};
