import type { DefaultOptionType } from "rc-cascader";
import type { SalesforceObject } from "@/api/salesforce-objects/types";
import { memoizeWith, sortBy } from "ramda";
import type { FieldType } from "@/types/salesforce";
import { stripUndefined } from "@/utils";
import type { OpenplayObjectField } from "@/api/openplay-objects/types";

type Params = {
  obj: SalesforceObject;
  nesting: number;
  excludeFieldTypes?: FieldType[];
  includeFieldTypes?: FieldType[];
  updatableOnly?: boolean;
};

const getCacheKey = ({
  obj,
  nesting = 0,
  excludeFieldTypes = [],
  includeFieldTypes = [],
  updatableOnly = false,
}: Params) =>
  [obj.name, nesting, excludeFieldTypes.join(","), includeFieldTypes.join(","), updatableOnly].join(":");

export const mapFieldsToOptions = memoizeWith<
  ({ obj, nesting, excludeFieldTypes, includeFieldTypes, updatableOnly }: Params) => DefaultOptionType[]
>(
  getCacheKey,
  ({
    obj,
    nesting = 0,
    excludeFieldTypes = [],
    includeFieldTypes = [],
    updatableOnly = false,
  }): DefaultOptionType[] => {
    const excludeReference = nesting === 0;
    return obj.fields
      .filter((field) => {
        if (updatableOnly && !field.updatable) {
          return false;
        }
        if (excludeReference && field.type === "reference") {
          return false;
        }
        if (includeFieldTypes.length > 0) {
          return includeFieldTypes.includes(field.type) || (!excludeReference && field.type === "reference");
        }
        return !excludeFieldTypes.includes(field.type);
      })
      .map((field) => ({
        label: field.label,
        value: field.name,
        type: field.type,
        children:
          field.type === "reference"
            ? mapFieldsToOptions({
                obj: field.referenceTo,
                nesting: nesting - 1,
                excludeFieldTypes,
                includeFieldTypes,
                updatableOnly,
              })
            : [],
      }));
  }
);

export const openplayPathsToOptions = (fields: OpenplayObjectField[], excludeArrays = false) =>
  sortBy(
    (field) => field.name,
    fields.filter(({ isArray, hidden }) => !(excludeArrays && isArray) && !hidden)
  ).map((field) => {
    const path = field.isArray ? field.name + "[]" : field.name;
    return stripUndefined({
      value: path,
      label: field.label || path,
      type: field.type,
      children: openplayPathsToOptions(field.children, excludeArrays),
    });
  });
