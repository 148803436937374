import Icon from "@ant-design/icons";
import type { ComponentProps, ComponentType, SVGProps } from "react";
import { forwardRef } from "react";
import cn from "classnames";
import "./IconButton.less";

type Props = {
  icon: ComponentType<SVGProps<SVGSVGElement>>;
  danger?: boolean;
} & ComponentProps<"button">;

export const IconButton = forwardRef<HTMLButtonElement, Props>(
  ({ icon, className, onClick, disabled, danger, ...rest }, ref) => (
    <button
      ref={ref}
      className={cn("icon-button", danger && "icon-button--danger", className)}
      type="button"
      onClick={onClick}
      disabled={disabled}
      {...rest}
    >
      <Icon component={icon} />
    </button>
  )
);
