import { Col, Divider, Form, Input, Row } from "antd";
import { Field } from "../../../../common/Form/Field";
import { Box } from "../../../../common/Box/Box";
import { useFieldPath } from "../../../../common/Form/hooks";
import { FormattingRuleList } from "@/components/Reports/Details/SpreadsheetTabs/FormattingRuleList/FormattingRuleList";
import { RowByField } from "@/components/Reports/Details/SpreadsheetTabs/SpreadsheetTab/RowByField/RowByField";
import { TabSourceField } from "@/components/Reports/Details/SpreadsheetTabs/SpreadsheetTab/TabSourceField";
import { TabSource } from "@/utils/tab-source";
import { getOpenPlayEquivalent } from "@/utils/getOpenPlayEquivalent";
import type { SalesforceQuery } from "@/api/reports/types";
import { ReportType } from "@/api/reports/types";
import { QueryPickerField } from "@/components/Reports/Details/SpreadsheetTabs/SpreadsheetTab/QueryPickerField";
import { ColumnSetForm } from "./ColumnSetForm/ColumnSetForm";
import { getFieldMaxLengthRule } from "@/utils/validation";
import { HiddenField } from "../../../../common/HiddenField";
import { useSpreadsheetFormContext } from "@/components/Spreadsheets/SpreadsheetForm/context";

const useSourceObject = () => {
  const { getAbsolutePath } = useFieldPath();
  const { entity } = useSpreadsheetFormContext();
  const reportType = Form.useWatch<ReportType>("type");
  const reportQueries = Form.useWatch<SalesforceQuery[]>("queries") ?? [];
  const selectedQueryId = Form.useWatch<string>(getAbsolutePath("query")) ?? null;
  const sourceObjectName = Form.useWatch<string>(["sourceObject", "name"]) ?? null;

  if (entity === "email-alert") {
    return sourceObjectName;
  }

  if (reportType === ReportType.SingleQuery) {
    return reportQueries[0]?.from;
  }
  return (selectedQueryId && reportQueries.find((query) => query.id === selectedQueryId)?.from) ?? null;
};

export const SpreadsheetTab = ({ disabled }) => {
  const { localPath, getAbsolutePath } = useFieldPath();
  const fieldIndex = localPath[0];
  const reportType = Form.useWatch<ReportType>("type");
  const tabSource = Form.useWatch(getAbsolutePath("source")) ?? TabSource.Salesforce;
  const sourceObject = useSourceObject();
  const openplayEquivalent = getOpenPlayEquivalent(sourceObject);

  return (
    <Box readOnly={disabled}>
      <Field hidden name="id">
        <Input />
      </Field>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Field
            name="name"
            label="Name"
            rules={[
              {
                required: true,
                transform: (value) => value?.trim(),
                message: "Field is mandatory",
              },
              getFieldMaxLengthRule("Name", 30),
              (form) => ({
                validator: (_, value) => {
                  const otherTabNames = form
                    .getFieldValue(["spreadsheet", "tabs"])
                    .filter((_, index) => fieldIndex !== index)
                    .map((tab) => tab.name);
                  return otherTabNames.includes(value)
                    ? Promise.reject("A tab with entered name already exists")
                    : Promise.resolve();
                },
              }),
            ]}
            validateFirst
          >
            <Input placeholder="Tab Name" />
          </Field>
        </Col>
        {reportType === ReportType.MultipleQuery && (
          <Col span={12}>
            <QueryPickerField />
          </Col>
        )}
        {!!openplayEquivalent ? (
          <>
            <Col span={12}>
              <TabSourceField />
            </Col>
            {tabSource === TabSource.OpenPlay && (
              <Col span={12}>
                <RowByField openplayObject={openplayEquivalent} />
              </Col>
            )}
          </>
        ) : (
          <HiddenField name="source" initialValue={TabSource.Salesforce} />
        )}
      </Row>
      <Divider orientation="left">Columns</Divider>
      <ColumnSetForm sourceObject={sourceObject} tabSource={tabSource} />
      <Divider orientation="left">Formatting Rules</Divider>
      <FormattingRuleList sourceObject={sourceObject} />
    </Box>
  );
};
