import { useCallback } from "react";
import { getColumns } from "./columns";
import { useRequest } from "ahooks";
import { getPartners } from "@/api/partners";
import { TableWithPaginationAndSorting } from "../../common";
import "./Partners.less";
import { PartnerTab, prepareParams, useAuth } from "@/utils";
import { useHistory } from "react-router-dom";
import { useGridParams } from "@/utils/useGridParams";

function PartnersTable() {
  const history = useHistory();
  const { filters, pagination, sort } = useGridParams();

  const { loading, data: listOfPartners } = useRequest(
    () => getPartners(prepareParams(filters, pagination, sort)),
    {
      refreshDeps: [filters, pagination, sort],
    }
  );

  const handleEdit = useCallback(({ id }) => history.push(`/partners/${id}/${PartnerTab.Summary}`), [
    history,
  ]);

  const { user } = useAuth();

  return (
    <>
      <TableWithPaginationAndSorting
        onRowClick={handleEdit}
        loading={loading}
        columns={getColumns(user?.role)}
        data={listOfPartners}
        tableLayout="fixed"
      />
    </>
  );
}

export default PartnersTable;
