import { Button, Form, Input, PageHeader, Popconfirm, Select, Tabs } from "antd";
import { FormActions } from "../../common/FormActions";
import { useCallback, useEffect, useMemo } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useNavigationConfirm } from "@/utils/useNavigationConfirm";
import { useBoolean, useRequest } from "ahooks";
import { deleteReport, saveReport } from "@/api/reports";
import { ReportTab } from "@/utils";
import * as R from "ramda";
import type { FormValues } from "./utils";
import { parseReportFields, prepareReportDto } from "./utils";
import { ReportSummaryForm } from "./ReportSummaryForm";
import { tab } from "@/utils/tab";
import { ReportEmailForm } from "./ReportEmailForm";
import { ReportQueries } from "./ReportQueries";
import { FormErrorsContext } from "../../common/Form/context";
import { ReportSpreadsheet } from "./ReportSpreadsheet";
import { useFormErrorsState } from "@/components/common/Form/hooks";
import { ExportReportButton } from "../ExportReportButton";
import { ReportTypeSelector } from "./ReportTypeSelector";
import { Summary } from "@/components/common/Summary/Summary";
import { getReportTypeLabel } from "@/utils/report-type";
import type { Report } from "@/api/reports/types";
import { ReportType } from "@/api/reports/types";
import { DownloadSpreadsheetButton } from "./DownloadSpreadsheetButton";
import { ReportActions } from "./ReportActions";

const fieldsByTab = {
  [ReportTab.Summary]: ["name", "recurrency"],
  [ReportTab.Email]: ["email"],
  [ReportTab.Query]: ["queries", "variations"],
  [ReportTab.Spreadsheet]: ["spreadsheet"],
  [ReportTab.Actions]: ["actions"],
};

type Props = {
  initialValues?: Partial<Report>;
  isEditingByDefault?: boolean;
};

export const ReportForm = ({ initialValues = {}, isEditingByDefault = false }: Props) => {
  const [form] = Form.useForm<FormValues>();

  const history = useHistory();
  const { allowNavigation, preventNavigation } = useNavigationConfirm();

  const [editing, { setTrue: enableEditing, setFalse: disableEditing }] = useBoolean(isEditingByDefault);

  const { activeTab = ReportTab.Summary } = useParams<{ activeTab: ReportTab }>();

  const onTabChanged = useCallback(
    (newTab: ReportTab) => history.replace(`/reports/${initialValues?.id || "new"}/${newTab}`),
    [initialValues, history]
  );

  const { fieldsWithErrors, handleFieldsChange, resetErrors } = useFormErrorsState();

  const rootFieldsWithErrors = fieldsWithErrors.map((field) => field.name[0]);

  const isSummaryTabError = R.intersection(fieldsByTab.summary, rootFieldsWithErrors).length > 0;
  const isEmailTabError = R.intersection(fieldsByTab.email, rootFieldsWithErrors).length > 0;
  const isQueryTabError = R.intersection(fieldsByTab.query, rootFieldsWithErrors).length > 0;
  const isSpreadsheetTabError = R.intersection(fieldsByTab.spreadsheet, rootFieldsWithErrors).length > 0;
  const isActionsTabError = R.intersection(fieldsByTab.actions, rootFieldsWithErrors).length > 0;

  const handleDelete = async () => {
    if (id) {
      await deleteReport(id);
      history.push(`/reports`);
    }
  };

  const initialFields = parseReportFields(initialValues);

  const handleCancel = () => {
    allowNavigation();
    if (initialValues.id) {
      form.setFieldsValue(initialFields);
      resetErrors();
      disableEditing();
    } else {
      history.push(`/reports`);
    }
  };

  const handleValueChange = () => {
    if (editing) {
      preventNavigation();
    }
  };

  const handleEdit = () => {
    enableEditing();
  };

  const { loading: saving, run: save } = useRequest(saveReport, { manual: true });

  const handleSave = async (dto) => {
    try {
      const { id } = await save(dto);
      form.setFieldValue("id", id);
      allowNavigation();
      history.push(`/reports`);
    } catch (e) {
      console.error("unhandled error", e);
    }
  };

  const handleFinish = async (values: FormValues) => {
    const dto = prepareReportDto(values);
    await handleSave(dto);
  };

  const id = Form.useWatch<string>("id", form);

  const isDeletable = !!id && !editing;
  const isExportable = !!id && !editing;
  const isSpreadsheetDownloadable = !!id && !editing;

  const title = initialValues.name ?? "New Report";

  const type = Form.useWatch("type", form);

  const handleTypeSelect = ({ type }) => {
    form.setFieldValue("type", type);
  };

  const tabs = useMemo(
    () => [
      tab({
        key: ReportTab.Summary,
        label: "Summary",
        hasErrors: isSummaryTabError,
        content: <ReportSummaryForm readOnly={!editing} />,
      }),
      tab({
        key: ReportTab.Query,
        label: type === ReportType.SingleQuery ? "Query + Variations" : "Queries",
        hasErrors: isQueryTabError,
        content: <ReportQueries readOnly={!editing} />,
      }),
      tab({
        key: ReportTab.Email,
        label: "Email",
        hasErrors: isEmailTabError,
        content: <ReportEmailForm readOnly={!editing} />,
      }),
      tab({
        key: ReportTab.Spreadsheet,
        label: "Spreadsheet",
        hasErrors: isSpreadsheetTabError,
        content: <ReportSpreadsheet readOnly={!editing} />,
      }),
      tab({
        key: ReportTab.Actions,
        label: "Actions",
        hasErrors: isActionsTabError,
        content: <ReportActions readOnly={!editing} />,
      }),
    ],
    [
      editing,
      isActionsTabError,
      isEmailTabError,
      isQueryTabError,
      isSpreadsheetTabError,
      isSummaryTabError,
      type,
    ]
  );

  useEffect(() => {
    if (history.location.state?.editMode) {
      enableEditing();
    }
  }, [history.location.state?.editMode, enableEditing]);

  return (
    <>
      <PageHeader
        title={<h1 style={{ margin: 0 }}>{title}</h1>}
        extra={
          <>
            {type && (
              <div style={{ marginRight: 16 }}>
                <Summary items={[{ label: "Type", value: getReportTypeLabel(type) }]} />
              </div>
            )}
            {isExportable && <ExportReportButton id={id} />}
            {isSpreadsheetDownloadable && <DownloadSpreadsheetButton form={form} />}
            <FormActions
              isEditing={editing}
              onSave={form.submit}
              isSaving={saving}
              onEdit={handleEdit}
              onCancel={handleCancel}
            />
            {isDeletable && (
              <Popconfirm
                title="Are you sure to delete the report?"
                onConfirm={handleDelete}
                okText="Yes"
                cancelText="No"
              >
                <Button>Delete</Button>
              </Popconfirm>
            )}
          </>
        }
      />
      <FormErrorsContext.Provider value={{ fieldsWithErrors }}>
        <Form
          form={form}
          initialValues={initialFields}
          onFinish={handleFinish}
          onFieldsChange={handleFieldsChange}
          onValuesChange={handleValueChange}
          validateMessages={{ required: "Field is mandatory" }}
          labelAlign="left"
        >
          <Form.Item hidden name="id">
            <Input />
          </Form.Item>
          <Form.Item hidden name="type">
            <Input />
          </Form.Item>
          <Form.Item hidden name="variables">
            <Select mode="multiple" />
          </Form.Item>
          {type ? (
            <Tabs activeKey={activeTab} onChange={onTabChanged} items={tabs} />
          ) : (
            <ReportTypeSelector onSelect={handleTypeSelect} />
          )}
        </Form>
      </FormErrorsContext.Provider>
    </>
  );
};
